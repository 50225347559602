/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { features } from '@config';
import classNames from 'classnames';
import useStyles from '@common_slick/Banner/style';
import Thumbor from '@common_image';
import ProductVideo from '@common_slick/Banner/productVideo';

/**
 slug page need props 'href' & 'as' to prevent browser reloading
 *isSlug == true => <link href="/[...slug]" as={link} />
 *isSlug == false => <link href={link} />
*/

const ImageSlide = ({
    width, height, imageUrl = '', link = '#', isSlug = true, mobileImageUrl = '', noLink,
    contentWidth, customClass = '', videoUrl, target = null, alt = '', priority = false,
}) => {
    const styles = useStyles();
    const href = (link && link.includes('http://')) || link.includes('https://') ? link : link[0] === '/' ? link : `/${link}`;

    if (noLink) {
        return (
            imageUrl && videoUrl && videoUrl.video_url
                ? <ProductVideo videoUrl={videoUrl} />
                : (
                    <Thumbor
                        src={imageUrl}
                        srcMobile={mobileImageUrl}
                        width={width || features.imageSize.homeSlider.desktop.width}
                        height={height || features.imageSize.homeSlider.desktop.height}
                        widthMobile={width || features.imageSize.homeSlider.mobile.width}
                        heightMobile={height || features.imageSize.homeSlider.mobile.height}
                        alt={alt || href}
                        quality={100}
                        className={
                            contentWidth === 'auto'
                                ? classNames(styles.imageSliderAuto, styles.imageSlider, customClass)
                                : classNames(styles.imageSlider, customClass)
                        }
                        slickBanner
                        lazy={!priority}
                        preload={priority}
                    />
                )
        );
    }
    if (href.includes('http')) {
        return (
            <a
                href={href[0] === '/' ? href.replace('/', '') : href}
                target={target || ''}
                rel={(target && target === '_blank') ? 'noopener noreferrer' : ''}
            >
                <Thumbor
                    src={imageUrl}
                    srcMobile={mobileImageUrl}
                    width={features.imageSize.homeSlider.desktop.width}
                    height={features.imageSize.homeSlider.desktop.height}
                    widthMobile={features.imageSize.homeSlider.mobile.width}
                    heightMobile={features.imageSize.homeSlider.mobile.height}
                    alt={alt || href}
                    quality={100}
                    className={contentWidth === 'auto' ? classNames(styles.imageSliderAuto, styles.imageSlider) : styles.imageSlider}
                    contentWidth={contentWidth}
                    customClass={customClass}
                    slickBanner
                    lazy={!priority}
                    preload={priority}
                />
            </a>
        );
    }

    return (
        <Link href={isSlug ? '/[...slug]' : href} {...(isSlug && { as: href })} prefetch={false}>
            <a target={target || ''} rel={(target && target === '_blank') ? 'noopener noreferrer' : ''}>
                <Thumbor
                    src={imageUrl}
                    srcMobile={mobileImageUrl}
                    width={features.imageSize.homeSlider.desktop.width}
                    height={features.imageSize.homeSlider.desktop.height}
                    widthMobile={features.imageSize.homeSlider.mobile.width}
                    heightMobile={features.imageSize.homeSlider.mobile.height}
                    alt={alt || href}
                    quality={100}
                    qualityMobile={70}
                    className={contentWidth === 'auto' ? classNames(styles.imageSliderAuto, styles.imageSlider) : styles.imageSlider}
                    contentWidth={contentWidth}
                    customClass={customClass}
                    slickBanner
                    lazy={!priority}
                    preload={priority}
                />
            </a>
        </Link>
    );
};

export default ImageSlide;
