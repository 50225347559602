/* eslint-disable consistent-return */
import config from '@config';
import dynamic from 'next/dynamic';
import gqlService from '@core_modules/home/service/graphql';
import BannerView from '@core_modules/home/pages/default/components/Banner/view';
import refetchBannerSlider from '@helpers/generateTimeInterval';
import { useEffect } from 'react';

const BannerSliderSkeleton = dynamic(() => import('@core_modules/home/pages/default/components/Skeleton/BannerSkeleton'), { ssr: false });

const BannerSlider = (props) => {
    const { home } = config.modules;
    const {
        storeConfig, t, slider_id, ErrorInfo,
    } = props;
    const logoUrl = `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`;
    const {
        loading,
        data,
        error,
        refetch,
    } = gqlService.getSlider({
        variables: {
            input: slider_id === undefined ? { title: home.bannerSlider.title } : { id: slider_id },
        },
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            refetchBannerSlider(refetch);
        }
    }, []);

    if (loading && !data) {
        return <BannerSliderSkeleton logoUrl={logoUrl} />;
    }
    if (error) {
        return <ErrorInfo variant="error" text={t('home:errorFetchData')} />;
    }
    if (!data || data.slider.images.length === 0) {
        return <ErrorInfo variant="warning" text={t('home:nullData')} />;
    }

    if (
        data
        && data.slider
        && data.slider.images
        && data.slider.images.length > 0
    ) {
        let sortByPosition = [];
        const withPosition = data.slider.images.filter((filtered) => filtered.position > 0) ?? [];
        const nullPosition = data.slider.images.filter((filtered) => !filtered.position) ?? [];

        if (withPosition.length > 0) {
            const withPositionSorted = withPosition.sort((a, b) => {
                if (a.position > b.position) return 1;
                if (a.position < b.position) return -1;
                return 0;
            });
            sortByPosition = [...withPositionSorted];
        }

        if (nullPosition.length > 0) {
            sortByPosition = [...sortByPosition, ...nullPosition];
        }

        const bannerImages = sortByPosition.map((image) => ({
            imageUrl: image.image_url,
            mobileImageUrl: image.mobile_image_url || image.image_url,
            link: image.url_redirection,
        }));

        return (
            <>
                <BannerView logoUrl={logoUrl} images={bannerImages} />
            </>
        );
    }

    return null;
};

export default BannerSlider;
